export default {
  AUCTIONS_LIST: "Liste des encans",
  ADD_AUCTION: "Ajouter un encan",
  VIEW_AUCTION: "Voir l'encan",
  AUCTION_ADDED: "Encan ajoutée",
  EDIT_AUCTION: "Modifier une encan",
  AUCTION_UPDATED: "Ecan mise à jour",
  DELETE_THIS_AUCTION: "Supprimer cet encan ?",
  DELAY: "Délai",
  AUCTION_TYPE: "Type d'encan",
  NAME: "Nom de l'encan",
  OBJECT_TYPE: "Type d'objet",
  AUTHORIZED_PAYMENTS: "Paiements autorisés",
  PAYMENT_SOURCE_BANK_DRAFT: "Traite bancaire",
  PAYMENT_SOURCE_CERTIFIED_CHECK: "Chèque certifié",
  PAYMENT_SOURCE_POST_ORDER: "Mandat poste",
  PAYMENT_SOURCE_CASH: "Argent comptant",
  PAYMENT_SOURCE_BANK_TRANSFER: "Transfert bancaire",
  PAYMENT_SOURCE_NORMAL_CHECK: "Chèque normale",
  PAYMENT_SOURCE_FIDEOCOMMIS_CHECK: "Chèque fidéocommis",
  PAYMENT_SOURCE_PAYMENT_SOURCE_NORMAL_CHECK: "Chèque normal",
  PRE_OPENING_AT: "Date d'ouverture pré-enchère",
  ACTIVATED_TIMER: "Minuteur activé",
  EXTENSION_TIME: "Temps d'extension",
  OBJECT_TYPE_PROPERTY: "Propriété",
  OBJECT_TYPE_CAR: "Voiture",
  AUCTION_TYPE_STANDARD: "Standard",
  AUCTION_TYPE_SILENT: "Silencieuse",
  TOTAL_PROPERTIES: "Propriété (Qté)",
  LISTINGS_REGISTRATIONS_OPEN_AT: "Date d’ouverture des inscriptions",
  LISTINGS_REGISTRATIONS_CLOSE_AT: "Date de fermeture des inscriptions",
  ADD_FEE_LEVEL: "Ajouter un palier",
  ADD_BID_STEP: "Ajouter un bond de mise",
  START_AT: "Date début de l'encan",
  UPDATE_FEES: "Actualiser les frais",
  PROPERTIES_FEE_UPDATED:
    "LES FRAIS MRC DES PROPRIÉTÉS DE CET ENCAN ONT ÉTÉ MIS À JOUR",
  ADD_FEES: "Ajouter un frais",
  AMOUNT: "Montant frais",
  PERCENT: "Taux (Pourcentage)",
  FEES_TYPE: "Type de frais",
  FEES_NAME: "Nom du frais",
  FEES_TYPE_NORMAL: 'Normal',
  FEES_TYPE_CLERK_LAW: 'Devoir greffier',
  FEES_TYPE_CONSIGNMENT: 'Consignations',
};
