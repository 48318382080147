import permissions from "../constants/permissions";
import AdminModuleLayout from "@/views/Pages/AdminModule/AdminModuleLayout.vue";
import AdminModuleMenu from "@/views/Pages/AdminModule/AdminModuleMenu.vue";

//import middleware
import authorized from "@/middleware/authorized";
import authenticated from "@/middleware/authenticated";

//import pages
const AdminPage = () => import("@/views/Pages/AdminModule/AdminPage.vue");

const ListRolePage = () =>
  import("@/views/Pages/AdminModule/RoleManagement/ListRolePage.vue");

const ListUserPage = () =>
  import("@/views/Pages/AdminModule/UserManagement/ListUserPage.vue");

const ListOrganizationPage = () =>
  import(
    "@/views/Pages/AdminModule/OrganizationManagement/ListOrganizationPage.vue"
  );

const TemplatesPage = () =>
  import("@/views/Pages/AdminModule/TemplatesManagement/TemplateModulesPage.vue");

const ListResellerPage = () =>
  import("@/views/Pages/AdminModule/ResellerManagement/ListResellerPage.vue");

const ListLocationPage = () =>
  import("@/views/Pages/AdminModule/LocationManagement/ListLocationPage.vue");

const ListImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/ListImportPage.vue");
/* const AddImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/AddImport.vue");
const EditImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/EditImportPage.vue");
const ViewImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/ViewImportPage.vue"); */

let userManagementPages = {
  path: "users",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Users",
      components: { default: ListUserPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_USERS,
        hasLevel2: true,
      },
    },
  ],
};

let roleManagementPages = {
  path: "roles",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Roles",
      components: { default: ListRolePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ROLES,
        hasLevel2: true,
      },
    },
  ],
};

let resellerManagementPages = {
  path: "resellers",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Resellers",
      components: { default: ListResellerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLERS,
        hasLevel2: true,
      },
    },
  ],
};

let organizationManagementPages = {
  path: "organizations",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Organizations",
      components: { default: ListOrganizationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ORGANIZATIONS,
        hasLevel2: true,
      },
    },
  ],
};

let templateManagementPages = {
  path: "templates",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "Templates Management",
      components: { default: TemplatesPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_ORGANIZATION_TEMPLATES,
        hasLevel2: true,
      },
    },
  ],
};

let locationsManagementPages = {
  path: "locations",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Locations",
      components: { default: ListLocationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_LOCATIONS,
        hasLevel2: true,
      },
    },
  ],
};

let importManagementPages = {
  path: "imports",
  component: AdminModuleLayout,
  children: [
    {
      path: "",
      name: "List Imports",
      components: { default: ListImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_IMPORTS,
      },
    },
    /* {
      path: "add",
      name: "Add Imports",
      components: { default: AddImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_IMPORTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Imports",
      components: { default: EditImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_IMPORTS,
      },
    },
    {
      path: "view/:id",
      name: "View Imports",
      components: { default: ViewImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_IMPORTS,
      },
    }, */
  ],
};

const adminModuleRoutes = [
  {
    path: "",
    components: {
      default: AdminModuleLayout,
      SidebarSecondLevelMenu: AdminModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Admin Page",
        components: { default: AdminPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  userManagementPages,
  roleManagementPages,
  organizationManagementPages,
  locationsManagementPages,
  resellerManagementPages,
  importManagementPages,
  templateManagementPages,
];

export default adminModuleRoutes;
